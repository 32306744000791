.wrapper{
  overflow-x: hidden;
  overflow-y:auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* navbar */
.navbox{
  width: 100%;
  height: auto;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 25px;
}

.navbox .leftside{
  width: 80px;
  height: 80px;
}

.navbox .leftside img{
  height: auto;
    max-width: 100%;
    object-fit: cover;
    vertical-align: middle;
}

.navbox .rightside{
  display: flex;
  flex-direction: row;
}

.navbox .rightside .navlinks{
   font-size: 12px;
   color: #000;
   font-weight: 600;
   margin:auto 10px;
}

.grayc{
  color:gray !important;
}
